import service from '@/utils/request';

// @Tags RmParamRelateData
// @Summary 创建RmParamRelateData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamRelateData true "创建RmParamRelateData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParamRelateData/createRmParamRelateData [post]
export var createRmParamRelateData = function createRmParamRelateData(data) {
  return service({
    url: "/rmParamRelateData/createRmParamRelateData",
    method: 'post',
    data: data
  });
};

// @Tags RmParamRelateData
// @Summary 删除RmParamRelateData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamRelateData true "删除RmParamRelateData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParamRelateData/deleteRmParamRelateData [delete]
export var deleteRmParamRelateData = function deleteRmParamRelateData(data) {
  return service({
    url: "/rmParamRelateData/deleteRmParamRelateData",
    method: 'delete',
    data: data
  });
};

// @Tags RmParamRelateData
// @Summary 删除RmParamRelateData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除RmParamRelateData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /rmParamRelateData/deleteRmParamRelateData [delete]
export var deleteRmParamRelateDataByIds = function deleteRmParamRelateDataByIds(data) {
  return service({
    url: "/rmParamRelateData/deleteRmParamRelateDataByIds",
    method: 'delete',
    data: data
  });
};

// @Tags RmParamRelateData
// @Summary 更新RmParamRelateData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamRelateData true "更新RmParamRelateData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /rmParamRelateData/updateRmParamRelateData [put]
export var updateRmParamRelateData = function updateRmParamRelateData(data) {
  return service({
    url: "/rmParamRelateData/updateRmParamRelateData",
    method: 'put',
    data: data
  });
};

// @Tags RmParamRelateData
// @Summary 用id查询RmParamRelateData
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.RmParamRelateData true "用id查询RmParamRelateData"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /rmParamRelateData/findRmParamRelateData [get]
export var findRmParamRelateData = function findRmParamRelateData(params) {
  return service({
    url: "/rmParamRelateData/findRmParamRelateData",
    method: 'get',
    params: params
  });
};

// @Tags RmParamRelateData
// @Summary 分页获取RmParamRelateData列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取RmParamRelateData列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /rmParamRelateData/getRmParamRelateDataList [get]
export var getRmParamRelateDataList = function getRmParamRelateDataList(params) {
  return service({
    url: "/rmParamRelateData/getRmParamRelateDataList",
    method: 'get',
    params: params
  });
};